/**
 * @description
 * Truncates a number to two fraction digits.
 * @example
 * truncate(5.1234) -> 5.12
 * truncate(5.6789) -> 5.68
 */
export const truncate = (value: number) => {
  return Math.round(value * 100) / 100;
};

/**
 * @description
 * Calculates the percentage of a given amount and truncates the result to two decimal places.
 *
 * @example
 * calculatePercentageOfAmount(100, 5) -> 5.00
 * calculatePercentageOfAmount(200, 10) -> 20.00
 */
export const calculatePercentageOfAmount = (amount: number, percent: number): number =>
  truncate((amount * percent) / 100);

/**
 * @description
 * Converts a decimal into a percentage.
 * @example 0.012 -> 1.2, 0.0256 -> 2.56
 */
export function convertDecimalToPercent(numberInput: string): string;
export function convertDecimalToPercent(numberInput: number): string;
export function convertDecimalToPercent(numberInput: string | number): string {
  const value = typeof numberInput === 'string' ? parseFloat(numberInput) : numberInput;
  return (value * 100).toString();
}

/**
 * @description
 * Converts a percentage into a decimal string. The most common use case is to transform values from `CurrencyInput` to send them to API.
 * Usual precision BE aims to is 4 fraction digits. Fraction digits equal 4 is set as default.
 * @example 5 -> 0.05, 5.2 -> 0.052, 2.63 -> 0.0263
 */
export function convertPercentToDecimal(numberInput: string, fractionDigits?: number): string;
export function convertPercentToDecimal(numberInput: number, fractionDigits?: number): string;
export function convertPercentToDecimal(numberInput: string | number, fractionDigits = 5): string {
  const value = typeof numberInput === 'string' ? parseFloat(numberInput) : numberInput;
  return ((value * 1000) / 100000).toFixed(fractionDigits);
}

/**
 * @description
 * Checks if a given decimal value is effectively zero, considering a specified precision.
 * By default, it checks up to 4 fraction digits.
 *
 * @param value - The decimal value to check, provided as a string or number.
 * @param precision - The number of fraction digits to consider (default: 5).
 *
 * @returns `true` if the value is effectively zero at the given precision, otherwise `false`.
 *
 * @example
 * isDecimalZero(0) // true
 * isDecimalZero("0.0000") // true
 * isDecimalZero("0.0001") // false
 * isDecimalZero("0.00000") // true
 * isDecimalZero("0.00001") // false
 */
export function isDecimalZero(value: string | number, precision = 5): boolean {
  if (isNaN(Number(value))) return false;
  return Number(value).toFixed(precision) === (0).toFixed(precision);
}

/**
 * Returns the absolute (positive) value of a number or numeric string.
 * @param value - A number or a string that can be parsed into a number.
 * @returns The absolute value.
 */
export const getAbsoluteValue = (value: number | string): number => {
  const num = typeof value === 'string' ? parseFloat(value) : value;
  return Math.abs(num);
};
